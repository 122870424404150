<template>
  <a-col
    :id="'trace-page-video-single' + index"
    :xs="24"
    :sm="24"
    :md="24"
    :lg="12"
    :xl="12"
  >
    <a-card size="small" hoverable style="cursor: auto">
      <a-row>
        <a-col :xs="24" :sm="24" :md="10" :lg="9" :xl="9" class="h-100">
          <img
            v-if="video.thumbnail_path"
            :id="'trace-page-video-' + index + '-thumbnail'"
            class="clickable video-img"
            :src="thumbnailUrl(video.thumbnail_path)"
            :alt="getFileName(video.fileName)"
            @click="handleVideoPlay"
          />
          <div v-else class="w-100 d-flex" style="height: 124px">
            <a-spin class="m-auto" size="large" />
          </div>
        </a-col>
        <a-col
          :xs="24"
          :sm="24"
          :md="14"
          :lg="15"
          :xl="15"
          class="d-flex flex-column justify-content-between"
        >
          <a-descriptions class="text-start ml-n3">
            <a-descriptions-item
              label="Cycle Identifier"
              span="3"
              :contentStyle="{
                display: 'flex',
                alignItems: 'center',
                width: '100% !imortant'
              }"
              :labelStyle="{
                display: 'flex',
                alignItems: 'center'
              }"
            >
              <!-- <div class="w-100"> -->
              <a-tag color="blue">
                {{ video?.cycle_identifier }}
              </a-tag>
              <a-tag :color="getCycleTagColor">
                {{ getCycleTagText }}
              </a-tag>
              <a-tooltip title="Bookmark this video">
                <span class="ml-auto">
                  <a-rate
                    :value="video.starred"
                    style="margin-top: -0.3em; font-size: 2.5em"
                    :count="1"
                    @change="handleBookmark"
                  />
                </span>
              </a-tooltip>
              <!-- </div> -->
            </a-descriptions-item>
            <a-descriptions-item
              label="Cycle Time"
              class="d-flex align-items-center"
              span="3"
              :label-style="{ width: '6em' }"
            >
              <a-tag color="blue">
                {{ video?.cycle_time }}
              </a-tag>
            </a-descriptions-item>

            <a-descriptions-item
              label="Missed Steps"
              class="d-flex align-items-center"
              span="3"
              :label-style="{ width: '7em' }"
            >
              <a-tag color="blue">
                {{ video?.missedStepsName?.length || 0 }}
              </a-tag>
            </a-descriptions-item>
          </a-descriptions>

          <div :id="isInterestingCycles ? 'area1' : 'area2'" class="ml-n3">
            <a-dropdown
              trigger="click"
              class="px-2 py-1 mr-2 mt-2"
              overlay-class-name="overlay-container"
              :get-popup-container="getPopupContainer"
            >
              <a-button>Show Missed Steps <DownOutlined /></a-button>
              <template #overlay>
                <a-menu>
                  <a-menu-item v-for="ms in video?.missedStepsName" :key="ms">
                    {{ ms }}
                  </a-menu-item>
                  <a-menu-item v-if="video?.missedStepsName?.length === 0">
                    No missed steps
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
            <a-button
              class="mt-2 pr-1"
              @click="$emit('showComments', true, index)"
            >
              <div class="d-flex justify-content-between">
                <span>Comments</span>

                <a-tag color="processing" class="ml-3">
                  {{ video.no_of_comments }}
                </a-tag>
              </div>
            </a-button>
          </div>
        </a-col>
      </a-row>
    </a-card>
  </a-col>
</template>
<script>
import VideoService from '../../../../services/videos';
import { DownOutlined } from '@ant-design/icons-vue';

export default {
  components: {
    DownOutlined
  },
  props: ['video', 'cycle', 'index'],
  emits: ['updateVideoUrl', 'handlePlay', 'showComments', 'updateVideo'],
  data: function() {
    return {
      playable: false,
      document: document
    };
  },
  computed: {
    getCycleTagColor() {
      return this.video.missedStepsName?.length > 0 ? 'error' : 'success';
    },

    getCycleTagText() {
      return this.video.missedStepsName?.length > 0 ? 'Bad' : 'Good';
    }
  },

  methods: {
    handleBookmark() {
      this.$emit('updateVideo', {
        videoName: this.video.fileName,
        updatedObj: { ...this.video, starred: !this.video.starred }
      });
      VideoService.updateTaskRecord(this.video.id, {
        starred: !this.video.starred
      });
    },

    getObjectLength() {
      return Object.keys(this.video).length;
    },

    async handleVideoPlay() {
      this.$emit('handlePlay', {
        video: this.video,
        index: this.index
      });
    },

    updateVideoUrl(videoName, url) {
      this.$emit('updateVideoUrl', videoName, url, this.index);
    },

    getFileName(filename) {
      let name = filename?.slice(0, 30);
      if (filename?.length > 30) name += '...';
      return name;
    },

    thumbnailUrl(thumbnail_path) {
      return (
        'https://retrocausal-video-thumbnails.s3.amazonaws.com/' +
        thumbnail_path +
        '512.jpg'
      );
    }

    // async fetchComments(modal = true) {
    //   let data = {
    //     comments: [],
    //     videoRecord: this.video,
    //     show: modal,
    //   };
    //   if (!this.video.entity_id) {
    //     this.$emit('showComments', data);
    //     return;
    //   }

    //   if (this.video.comments?.length) {
    //     data['comments'] = [...this.video.comments];
    //   } else {
    //     const comment_response = await VideoService.getCommentsForEntity(
    //       this.video.entity_id
    //     );
    //     data['comments'] = [...comment_response];
    //   }
    //   this.$emit('showComments', data);
    // },
  }
};
</script>

<style scoped>
.video-img {
  width: 60%;
  height: 100%;
  border-radius: 5px;
}

.rating {
  width: 100%;
  margin-top: 12px;
  justify-content: center;
}

@media only screen and (max-width: 991px) {
  .video-img {
    height: 240px;
    margin-bottom: 12px;
  }
}
</style>
