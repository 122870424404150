<template>
  <a-row id="trace-page-container" :gutter="[16, 16]" class="py-4 px-2 p-md-4">
    <a-col span="24">
      <Filters
        ref="filtersComponent"
        :show-cycle-input="true"
        :show-duration-input="true"
        :show-steps-filter="true"
        @change="handleGetData"
        @updateCards="
          data => updateCards(headerCards, headerCardsSecondRow, data)
        "
      />
    </a-col>
    <a-col span="24" class="text-right">
      <a-button
        v-if="!isNoDataAvailable"
        id="trace-page-analytics-btn"
        :disabled="isFetchingCycles"
        type="primary"
        class="ml-auto"
        @click="$refs.filtersComponent.redirectToPage('Analytics')"
      >
        Analytics
        <template #icon>
          <i class="bi bi-box-arrow-up-right mr-2" />
        </template>
      </a-button>
    </a-col>

    <a-col span="24">
      <a-divider orientation="left" class="mt-2"> Dashboard </a-divider>
      <a-typography
        v-if="isDataNotFound && !isFetchingCycles"
        class="text-center mb-3"
        :level="4"
      >
        No Data Available
      </a-typography>
    </a-col>
    <!-- <a-row :gutter="[20, 4]"> -->
    <a-col
      v-for="(card, cardIndex) in cards"
      :key="card.key"
      :xs="card.colXS"
      :sm="card.colSM"
      :md="card.colMD"
      :lg="card.colLG"
      :xl="card.colXL"
    >
      <CardItem :card="card" :cardIndex="cardIndex" />
    </a-col>
    <!-- </a-row> -->

    <a-col
      v-if="topFivePercentile.length"
      id="trace-page-dashboard-video-box"
      class="mb-3"
      span="24"
    >
      <video-listing
        :total-videos="topFivePercentile.length"
        :list="topFivePercentile"
        :title="'Interesting Cycles'"
        :cycle="cycle_type"
        :get-video-s3-details="getVideoS3Details"
        :fetch-presigned-url="fetchPresignedUrl"
        :is-interesting-cycles="true"
        @updateVideo="updateVideo"
        @updateVideoUrl="updateVideoUrlInVideosAndPercentileVideos"
      />
    </a-col>

    <!-- Video Listing -->
    <a-col id="trace-page-dashboard-video-box" span="24">
      <video-listing
        :total-videos="videoCount"
        :list="videoList"
        :cycle="cycle_type"
        :is-interesting-cycles="false"
        :get-video-s3-details="getVideoS3Details"
        :fetch-presigned-url="fetchPresignedUrl"
        :current-page="pagination.currentPage"
        :page-size="pagination.pageSize"
        @handlePageChange="handleCurrentPageChange"
        @updateVideo="updateVideo"
        @updateVideoUrl="updateVideoUrlInVideosAndPercentileVideos"
      />
    </a-col>

    <a-col
      v-if="videoCount > 0"
      id="trace-video-pagination"
      span="24"
      class="d-flex"
    >
      <a-pagination
        id="trace-page-dashboard-videos-pagination"
        v-model:current="pagination.currentPage"
        v-model:pageSize="pagination.pageSize"
        class="mx-auto mt-2"
        :total="videoCount"
        show-size-changer
        :show-total="
          (total, range) => `${range[0]}-${range[1]} of ${total} cycles`
        "
        :page-size-options="pagination.options"
        @change="onPageChange"
      />
    </a-col>
  </a-row>
</template>

<script>
import httpClient from 'src/service/httpClient';
import CardItem from '../../../shared/Components/CardItemV3.vue';
import TelemetryService from 'src/services/telemetry';
import VideoListing from './VideoListing.vue';
import getTime from '../../../shared/Helpers/getTime';
import { mapActions, mapGetters } from 'vuex';
import { cards } from 'src/config/charts-config.js';
import Filters from '../../../shared/Components/AnalyticsTraceFilters.vue';
import traceMixin from '../../../../mixins/analyticsTrace';

export default {
  components: {
    VideoListing,
    CardItem,
    Filters
  },
  mixins: [traceMixin],
  inject: ['toast'],

  data() {
    return {
      cards: JSON.parse(JSON.stringify(cards)),
      cardsData: [],
      isDataNotFound: false,
      cycle_type: 'all_cycles',
      //===============================
      videoList: [],
      topFivePercentile: [],
      videoCount: 0,
      hasFetchCalled: false,
      pagination: {
        pageSize: 6,
        currentPage: 1,
        options: [6, 10, 20, 50]
      }
      //===============================
    };
  },

  computed: {
    ...mapGetters(['organization', 'isFetchingCycles']),
    isNoDataAvailable() {
      return Object.keys(this.cardsData).length === 0;
    }
  },

  watch: {
    videoList(list) {
      if (this.hasFetchCalled) return;
      const videosWithoutThumbnail = list.filter(v => !v.thumbnail_path);
      this.fetchAndUpdateThumbnails(videosWithoutThumbnail);
    }
  },

  methods: {
    ...mapActions(['setIsFetchingCycles']),
    async fetchAndUpdateThumbnails(videosWithoutThumbnail) {
      this.hasFetchCalled = true;
      for await (const video of videosWithoutThumbnail) {
        const { bucket, filePath: path } = this.getVideoS3Details(video);
        const url = await this.fetchPresignedUrl(bucket, path);
        const thumbnail_path = await this.getThumbnailPath(url, bucket, path);
        await this.updateTaskRecord(thumbnail_path, video);
      }
      this.hasFetchCalled = false;
    },

    getVideoS3Details(video, isPredictionFile = false) {
      return this.$refs.filtersComponent.getVideoS3Details(
        video,
        isPredictionFile
      );
    },

    async fetchPresignedUrl(bucket_name, object_path) {
      const payload = {
        bucket_name,
        object_path
      };
      return new Promise(async resolve => {
        const res = await httpClient.post(
          'generic/generate_new_url/',
          payload,
          false,
          false,
          false
        );
        resolve(res.presigned_url || res);
      });
    },

    async getThumbnailPath(file_url, bucket_name, file_path) {
      const payload = {
        file_url,
        bucket_name,
        file_path
      };
      return await httpClient.post(
        'generic/create_thumbnail/',
        payload,
        false,
        false,
        false
      );
    },

    updateTaskRecord(updatedData, videoToUpdate) {
      const updateVideoParams = {
        videoName: videoToUpdate.fileName,
        updatedObj: updatedData
      };
      return new Promise(async resolve => {
        this.updateVideo(updateVideoParams);
        await httpClient.patch(
          'organization/task_record/',
          videoToUpdate.id + '/',
          updatedData,
          false,
          false,
          false
        );
        resolve();
      });
    },

    updateVideo(params) {
      this.updateVideoDetails(params, 'videoList');
      this.updateVideoDetails(params, 'topFivePercentile');
    },

    updateVideoDetails(params, stateKey) {
      const { videoName, updatedObj } = params;
      const temp = [...this[stateKey]];
      const index = temp.findIndex(v => v.fileName === videoName);
      if (temp[index]) {
        temp[index] = {
          ...temp[index],
          ...updatedObj
        };
      }
      this[stateKey] = temp;
    },

    handleCurrentPageChange(page, imageChangeCb) {
      if (!page) return;
      this.pagination.currentPage = page;
      this.hasFetchCalled = false;
      this.$refs.filtersComponent.getData(true, imageChangeCb);
    },

    onPageChange() {
      this.hasFetchCalled = false;
      this.$refs.filtersComponent.getData(true);
    },

    getAverageCycleTime(sumOfCyclesTime, cyclesCount) {
      const averageCycleTime = sumOfCyclesTime / cyclesCount;
      return getTime(averageCycleTime * 1000);
    },

    async handleGetData(
      filters,
      isTraceOnly = false,
      imageChangeCb = () => {}
    ) {
      if (!filters.cyclesFromParams && !isTraceOnly) {
        this.getTaskData(filters);
      }
      const { currentPage, pageSize } = this.pagination;
      await this.getVideoList({
        ...filters,
        page: currentPage,
        page_size: pageSize
      });
      if (imageChangeCb) imageChangeCb();
    },

    async getTaskData(filters) {
      if (Array.isArray(this.cyclesFromParams)) return;
      this.setIsFetchingCycles(true);
      const [error, data] = await TelemetryService.fetchCyclesDetailsV2(
        false,
        filters
      );
      this.setIsFetchingCycles(false);
      if ((data?.body && data?.body == 'No result found') || error) {
        this.clearCards();
        this.cardsData = {};
        this.isDataNotFound = true;
        return;
      }

      this.isDataNotFound = false;

      this.cardsData = data;
      this.topFivePercentile = this.cardsData['top_five_percentile'];
      this.updateCards();
    },

    updateCards() {
      this.cards = this.cards.map(card => {
        card['value'] = this.cardsData[card.key];
        if (Object.hasOwn(card, 'goodCycles'))
          card['goodCycles'] = this.cardsData['cycles_without_errors'];
        if (Object.hasOwn(card, 'badCycles'))
          card['badCycles'] = this.cardsData['cycles_with_errors'];
        if (Object.hasOwn(card, 'percentile'))
          card['percentile'] = this.cardsData['top_five_percentile']?.length
            ? this.cardsData['top_five_percentile'].length
            : 0;
        return card;
      });
    },

    clearCards() {
      this.cards = this.cards.map(card => {
        card['value'] = 0;
        if (Object.hasOwn(card, 'goodCycles')) card['goodCycles'] = 0;
        if (Object.hasOwn(card, 'badCycles')) card['badCycles'] = 0;
        if (Object.hasOwn(card, 'percentile')) card['percentile'] = 0;
        return card;
      });
    },

    updateVideoUrlInVideosAndPercentileVideos(videoName, url) {
      this.updateVideoUrl(videoName, url, 'videoList');
      this.updateVideoUrl(videoName, url, 'topFivePercentile');
    },

    updateVideoUrl(videoName, url, stateKey) {
      const temp = [...this[stateKey]];
      const index = temp.findIndex(v => v.cycle_identifier === videoName);
      temp[index].video_url = temp[index].fileURL = url;
      this[stateKey] = temp;
    }
  }
};
</script>

<style>
ul {
  list-style-type: none;
  padding: 0px;
}

li.li {
  display: inline;
  margin: 0px 10px;
}

#trace-video-pagination
  ul
  > li:last-child
  .ant-pagination-options-size-changer.ant-select {
  width: 86px !important;
}
</style>
