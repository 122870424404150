<template>
  <trace-component></trace-component>
</template>

<script>
import TraceComponent from './TraceComponent.vue';

export default {
  components: {
    TraceComponent,
  },
};
</script>
